import { LandingPageType } from '@shared/types';

export const STATUSES = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
  PUBLISHED_TO_CAREER_PAGE: 'published_to_career_page',
} as const;

export const LANDING_PAGE_TYPES: Record<string, LandingPageType> = {
  CUSTOM_LANDING_PAGE: 'CustomLandingPage',
  TEMPLATE_LANDING_PAGE: 'TemplateLandingPage',
};
